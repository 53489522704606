import { createContext, useContext } from 'react';
//
import { BreadcrumbContextType } from '../types';

// ----------------------------------------------------------------------

export const BreadcrumbContext = createContext({} as BreadcrumbContextType);


export const useBreadcrumbContext = () => {
    const context = useContext(BreadcrumbContext);
  
    if (!context) throw new Error('useBreadcrumbContext must be use inside SettingsProvider');
  
    return context;
  };