


export const SECTIONS = {
    SPORTS:"sports",
    SURVEILLANCE:"surveillance",
    NOTIFICATIONS:"notification",
    CASINO_GAMES:"casino game",
    USERS:"users",
    DEVELOPER:"developer",
    SETTINGS:"settings",
    SITE_CMS:"site Management",
    BETS:"bets",
    TRANSFER:"transfer",
    ALL_LOGS:"all logs",
    
}

export const SUBSECTIONS = {
    BETS:{
        EVENT_BETS:"Event Bets",
        CASINO_BETS:"Casino Bets",
        PREVIOUS_BETS:"Previous Bets"
    },
    USERS:{
        SUPERADMINS:"super admin",
        CA_USERS:"CA Users",
        Clients:"Client",
    },
    SETTINGS:{
        MASTER_CONTROLLER:"Master Controller"
    },
    DEVELOPER:{
        DEV_TOOLS:"Dev Tools",
        MAINTENANCE:"Maintenance Mode"
    },
    ALL_LOGS:{
        SESSION_LOGS:"Session Logs"
    }
}

export const SPORT_FEATURES = {
    CATEGORYLIMITS:"Category Limits",
    SPORTLIMITS:"Sport limits",
    LEAGUELIMITS:"League Limits",
    EVENTLIMITS:"Event Limits",
    MARKETLIMITS:"Market Type Limits",

    CATEGORY_STATUS:"Category Status",
    EVENT_STATUS:"Event Status",
    EVENT_BET_LOCK:"Event Bet Lock",
    STREAM_AVAILABLE:"Stream Available",
    SCORE_AVAILABLE:"Score Available",
    POPULAR:"Popular",
    LIVE_STREAM:"Live Stream",
    LIVE_SCRORE:"Live Scores",

    MARKET_STATUS:"Market Status",
    MARKET_BET_LOCK:"Market Bet Lock",

    EVENTVOID:"Event Void Bets",
    MARKETVOID:"Marketwise Void Bets",
    RESULTDECLARE:"Result Declare",
    RESULTVERIFY:"Result Verify",
    
    ODDS_GRAPH:"Event Odds Graph",
}

export const BET_FEATURES = {
    BETVOID : "Void Bets"
}

export const SURVEILLANCE_FEATURES = {
    BETVOID : "Void Bets"
}

export const CA_USERS_FEATURES = {
    ADD_CA:"Add New CA",
    CA_STATUS:"CA Status",
    EDIT_CA:"Edit CA",
}

export const CLIENTS_FEATURES = {
    USER_STATUS:"User Status",
    BET_LOCK:"Bet Lock",
    TRANSFER_LOCK:"Transfer Lock",
}

export const SUPER_ADMIN_FEATURES = {
    ADD_SA:"Add Super Admin",
    BET_LOCK:"Bet Lock",
    TRANSFER_STATUS:"Transfer Status",
    SA_STATUS:"SA Status",
    EDIT_SA:"Edit SA",
}


export const CASINO_GAME_FEATURES = {
    ADD_GAME:"Add Game",
    EDIT_GAME:"Edit Game",
    DELETE_GAME:"Delete Game",
    DESKTOP_VISIBILITY:"Desktop Visibility",
    MOBILE_VISIBILITY:"Mobile Visibility",
}