import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  TEMPLOGIN = 'TEMPLOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.TEMPLOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  tempUser:null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.TEMPLOGIN) {
    return {
      ...state,
      tempUser: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get(endpoints.auth.me);
        let user;
        if(response.data && !response.data.response_error && response.data.data.length>0)
        {
          user = response.data.data[0];
        }
        // if(user && user.is_company_admin_user){
        //   const permission = await axios.get(endpoints.roles.getUserPermission(user.admin_id));
        //   // console.log(permission);
        //   if(permission.data && !permission.data.response_error && permission.data.data.UserPermission.length >0)
        //   {
        //     user.permissions = permission.data.data.UserPermission;
        //   }
        // }
        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (user_name: string, password: string) => {
    const reqData = {
      user_name,
      password,
    };

    const response = await axios.post(endpoints.auth.login, reqData);
    const { token, data: user } = response.data;

    


    if(!user.is_password_changed){ 
      dispatch({
        type: Types.TEMPLOGIN,
        payload: {
          user:{...user,token},
        },
      });
      return false;
    }
    dispatch({
      type: Types.LOGIN,
      payload: {
        user,
      },
    });
    setSession(token);
    initialize()
    return true;
  }, [initialize]);

  // REGISTER
  const register = useCallback(
    async (password: string) => {
      const data = {
        password,
      };

      console.log(state.tempUser)
      const response = await axios.put(endpoints.auth.register(state.tempUser?.admin_id || ""), data,{headers:{Authorization:state.tempUser?.token}});

      // const { accessToken, user } = response.data;

      // sessionStorage.setItem(STORAGE_KEY, accessToken);

      // dispatch({
      //   type: Types.REGISTER,
      //   payload: {
      //     user,
      //   },
      // });
    },
    [state.tempUser]
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      tempUser:state.tempUser,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status,state.tempUser]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
