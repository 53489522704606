import { useCallback, useEffect, useMemo, useState } from 'react';
// components
import { Icon } from '@iconify/react';   
import CustomBreadcrumbs, { BreadcrumbsLinkProps } from "src/components/custom-breadcrumbs";
import { useActiveLink,useParams,usePathname } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { useBreadcrumbContext } from 'src/components/custom-breadcrumbs/context';

type Page = {
    title:string,
    href:string,
    child?:Record<string, Page>,
    isDynamic?:boolean,
    getter?:any
}


export default function PageBreadcrumb(){
    const [breadcrumbLinks,setBreadcrumbLinks] = useState<BreadcrumbsLinkProps[]>([
        { name: '' },
    ])
    const pathname = usePathname();
    const { sportId } = useParams();

    const { getDynamicSportName,dynmicName } = useBreadcrumbContext();

    const Pages:Record<string, Page> = useMemo(()=>({
        sports:{
            title:"Sports",
            href:paths.dashboard.sports.root,
            child:{
                'dynamic':{
                    title:"",
                    href:"",
                    isDynamic:true,
                    getter:(value:string)=>getDynamicSportName(value),
                    child:{
                        "dynamic":{
                            title:"",
                            href:"",
                            isDynamic:true,
                        }
                    }
                }
            }
        },
        bets:{
            title:"Bets",
            href:paths.dashboard.bets.root,
            child:{
                '':{
                    title:"Event Bets",
                    href:paths.dashboard.bets.root,
                },
                casino:{
                    title:"Casino Bets",
                    href:paths.dashboard.bets.casino,
                },
                previous:{
                    title:"Prevoius Bets",
                    href:paths.dashboard.bets.previous,
                },
            }
        },
        users:{
            title:"Users",
            href:"",
            child:{
                ca_users:{
                    title:"CA Users",
                    href:paths.dashboard.users.CA_users.root,
                },
                clients:{
                    title:"Clients",
                    href:paths.dashboard.users.clients.root,
                    child:{
                        "dynamic":{
                            title:"",
                            href:"",
                            isDynamic:true,
                        }
                    }
                },
                super_admins:{
                    title:"Super Admins",
                    href:paths.dashboard.users.super_admins.root,
                },
    
            }
        },
        all_logs:{
            title:"All Logs",
            href:'',
            child:{
                session:{
                    title:"Session Logs",
                    href:paths.dashboard.all_logs.session_logs,
                },
                multiple_ip:{
                    title:"Mulitple IP Logs",
                    href:paths.dashboard.all_logs.multiple_ip_logs,
                },
                error:{
                    title:"Error Logs",
                    href:paths.dashboard.all_logs.error_logs,
                },
            }
        },
        settings:{
            title:"Settings",
            href:paths.dashboard.settings.root
        },
        developer:{
            title:"Developer",
            href:paths.dashboard.developer.root
        },
        transfer:{
            title:"Transfer",
            href:paths.dashboard.transfer
        },
        sites:{
            title:"Site Management",
            href:paths.dashboard.sites.list
        },
        surveillance:{
            title:"Surveillance",
            href:paths.dashboard.surveillance
        },
        casino_cms:{
            title:"Casino CMS",
            href:paths.dashboard.casino_cms
        },
        site_notications:{
            title:"Notifications",
            href:paths.dashboard.notification
        }
    }),[getDynamicSportName]) 

    const getBreadcrumbLinkItem = useCallback((currentPath:string[],currentLevel:number,page:Record<string, Page>):BreadcrumbsLinkProps[] | null =>{
        const newpage = page[currentPath[currentLevel] || ''] || page.dynamic;
        
        
        if(!newpage || !currentPath[currentLevel]) return null;
        if(!newpage.isDynamic){
            let childLinks:BreadcrumbsLinkProps[] = [];
            if(newpage.child){
                const checkLinks = getBreadcrumbLinkItem(currentPath,currentLevel+1,newpage.child);
                if(checkLinks){
                    childLinks = [...checkLinks];
                }
            }
            return [{name:newpage.title,href:newpage.href},...childLinks]
        }
        let childLinks:BreadcrumbsLinkProps[] = [];
       
        if(newpage.child){
            const checkLinks = getBreadcrumbLinkItem(currentPath,currentLevel+1,newpage.child);
            if(checkLinks){
                childLinks = [...checkLinks];
            }
        } 
        return [{name:newpage.getter ? newpage.getter(sportId) : dynmicName,href:""},...childLinks]
    },[sportId,dynmicName])

    const buildBredcrumb = useCallback(() =>{
        const currentPath = pathname.split("/");
        const currLevel  = 2
        const newBreadcrumbLinks = getBreadcrumbLinkItem(currentPath,currLevel,Pages);
        console.log(newBreadcrumbLinks)
        if(newBreadcrumbLinks){
            setBreadcrumbLinks(newBreadcrumbLinks)
        }
        else{
            setBreadcrumbLinks([{name:''}])
        }
    },[pathname,getBreadcrumbLinkItem,Pages]);




    useEffect(()=>{
        buildBredcrumb();
    },[pathname,buildBredcrumb,dynmicName]);

    

    // const activelinks = useActiveLink();


    return(
        <CustomBreadcrumbs
            separator={<Icon icon="solar:double-alt-arrow-right-bold-duotone"  fontSize={18} />}
            links={breadcrumbLinks}
        />
    )
}