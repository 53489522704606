import { m } from 'framer-motion';
// @mui
import { Theme, SxProps } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// assets
import { ForbiddenIllustration } from 'src/assets/illustrations';


// components
import { MotionContainer, varBounce } from 'src/components/animate';
import { getSectionPermissionValues } from 'src/utils/custom-functions';

// types
import { ISectionPermission } from 'src/types/roles';

import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  hasContent?: boolean;
  roles?: string[];
  children: React.ReactNode;
  permission_name:string,
  is_section:boolean,
  sx?: SxProps<Theme>;
};

export default function RoleBasedGuard({ hasContent, roles, children, sx,permission_name,is_section }: RoleBasedGuardProp) {
  // Logic here to get current user role
  // const { user } = useMockedUser();
  const { user } = useAuthContext();

  const restricted = getSectionPermissionValues(permission_name,user?.permissions)?.restrict_access || false;
  // const restricted = false;

  // const currentRole = 'user';
  // const currentRole = user?.role; // admin;

  if (restricted) {
    return  (
      <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration
            sx={{
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />
        </m.div>
      </Container>
    )
  }

  return <> {children} </>;
}
